export const STATE = {
    LOADING: "loading",
    BUSINESS_STRUCTURE: "businessStructure",
    ENTITY_TYPES: "entityTypes",
    ENTITY_PURPOSES: "entityPurposes",
    ERROR_MESSAGE: "errorMessage",
    VALIDATION_MESSAGES: "validationMessages",
    AVAILABLE_BUSINESS_STRUCTURES: "availableBusinessStructures",
    AVAILABLE_FARMS: "availableFarms",
    AVAILABLE_FARMS_LOADING: "availableFarmsLoading"
};

export const ACTIONS = {
    LOAD_BUSINESS_STRUCTURE: "loadBusinessStructure",
    STORE_ENTITY: "storeEntity",
    SET_IS_COMPLETE: "setIsComplete",
    DELETE_ENTITY: "deleteEntity",
    CLEAR_VALIDATION_MESSAGES: "clearValidationMessages",
    LOAD_AVAILABLE_BUSINESS_STRUCTURES: "loadAvailableBusinessStructures",
    LOAD_AVAILABLE_FARMS: "loadAvailableFarms",
    CLEAR_FARMS: "clearFarms",
    MERGE_BUSINESS_STRUCTURE: "changeBusinessStructure",
    UPDATE_BUSINESS_STRUCTURE: "updateBusinessStructure",
};

export const MUTATIONS = {
    SET_LOADING: "setLoading",
    SET_BUSINESS_STRUCTURE: "setBusinessStructure",
    SET_ENTITY_TYPES: "setEntityTypes",
    SET_ENTITY_PURPOSES: "setEntityPurposes",
    SET_ERROR_MESSAGE: "setErrorMessage",
    SET_VALIDATION_MESSAGES: "setValidationMessages",
    CLEAR_VALIDATION_MESSAGES: "clearValidationMessages",
    UPSERT_BUSINESS_STRUCTURE: "upsertBusinessStructure",
    UPDATE_BUSINESS_STRUCTURE_NAME: "updateBusinessStructureName",
    PUT_ENTITY_IN_BUSINESS_STRUCTURE: "putEntitiyInBusinessStructure",
    SET_AVAILABLE_BUSINESS_STRUCTURES: "setAvailableBusinessStructures",
    SET_AVAILABLE_FARMS: "setAvailableFarms",
    SET_AVAILABLE_FARMS_LOADING: "setAvailableFarmsLoading",
    HANDLE_RESPONSE_ERROR: "handleResponseError"
};

export const GETTERS = {
    IS_COMPLETE: "isComplete",
};

export default {
    STATE,
    ACTIONS,
    MUTATIONS,
    GETTERS
}